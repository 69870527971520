.c-author {
    position: absolute;
    bottom: 20px;
    left: 20px;

    &__avatar {
        width: 160px;
        height: 160px;
        border-radius: 20px;
        margin-bottom: 10px;
    }

    &__name {
        background: #444;
        color: #fff;
        border-radius: 20px;
        font-size: 1.625rem;
        padding: 2px 15px;
    }
}