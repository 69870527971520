.c-instructions {
    padding: 50px;

    &-item {
        display: flex;
        align-items: center;
    }

    &-item__icon {
        height: 260px;

        img {
            height: 100%;
            min-width: 307px;
        }
    }

    &-item__description {
        font-size: 3.125rem;
        font-weight: 300;
        color: #fff;
        text-align: left;

        strong {
            font-weight: 600;
        }
    }
}

.c-challenge-info {
    z-index: 9;

    &__title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 3.75rem;
      font-weight: 600;
      color: #fff;      

      img {
        margin-right: 30px;
        height: 60px;
      }
    }

    &__description {
      font-size: 1.875rem;
      color: #B3A9A4;
      width: 70vw;
      margin-bottom: 40px;
    }

    &__instructions {
      font-size: 2.5rem;
      font-weight: 300;
      color: #fff;      
      width: 70vw;
    }
}