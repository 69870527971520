.c-adjustment-chart {
    font-family: 'Titillium Web', sans-serif;
    margin: 0;
    padding: 0;
  
    width: 100vw;
    height: 100vh;
  
    background: #7F7F7F;
    background-image: linear-gradient(#fff 2px, transparent 2px), linear-gradient(90deg, #fff 2px, transparent 2px);
    background-size: 80px 80px;
    background-position: center; 
  
    display: flex;
    align-items: center;
    justify-content: center;
  
    position: relative;
  
    &__fullscreen {
      font-family: 'Titillium Web', sans-serif;
      font-size: 36px;
      color: #fff;
      background: #FA6161;
      position: absolute;
      top: 40px;
      padding: 10px 30px;
      text-decoration: none;
      border: none;
      cursor: pointer;
  
      &:hover {
        opacity: 0.8;
      }
  
      &:active,
      &:focus {
        outline: none;
      }
    }
  
    &__arrow{
      &--t1, &--t2, &--l1, &--l2, &--r1, &--r2, &--b1, &--b2 {
        background: url('./images/arrow.svg') no-repeat;
        background-size: 30px;
        position: absolute;
        width: 30px;
        height: 60px;
      }
  
      &--t1, &--t2 {
        top: 0;
      }
      &--t1 {
        left: 200px;
      }
      &--t2 {
        right: 200px;
      }    
  
      &--l1, &--l2 {
        left: 16px;
        transform: rotate(-90deg);
      }
      &--l1 {
        top: 200px;
      }
      &--l2 {
        bottom: 200px;
      }
  
      &--r1, &--r2 {
        right: 16px;
        transform: rotate(90deg);
      }
      &--r1 {
        top: 200px;
      }
      &--r2 {
        bottom: 200px;
      }   
  
      &--b1, &--b2 {
        bottom: 0;
        transform: rotate(180deg);
      }
      &--b1 {
        left: 200px;
      }
      &--b2 {
        right: 200px;
      }     
    }
  
    &__content {
      width: 1280px;
      background: #fff;
      position: relative;
    }
  
    &__colors {
      width: 100%;
      display: flex;
      flex-direction: row;
  
      &--1, &--2, &--3, &--4, &--5, &--6, &--7, &--8 {
        height: 160px;
        flex: 1;
      }
      &--1 {
        background: #fff;
      }
      &--2 {
        background: #fffe05;
      }
      &--3 {
        background: #01ffff;
      }
      &--4 {
        background: #00ff01;
      }
      &--5 {
        background:#ff00fe;
      }
      &--6 {
        background: #fe0000;
      }
      &--7 {
        background: #0000fe;
      }
      &--8 {
        background: #000;
      } 
    }
  
    &__grayscale {
      width: 100%;
      display: flex;
      flex-direction: row;
  
      &--1, &--2, &--3, &--4, &--5, &--6, &--7, &--8, &--9, &--10, &--11, &--12, &--13, &--14, &--15, &--16 {
        height: 160px;
        flex: 1;
      }
      &--1 {
        background: #000000;
      }
      &--2 {
        background: #161616;
      }
      &--3 {
        background: #212121;
      }
      &--4 {
        background: #2C2C2C;
      }
      &--5 {
        background:#373737;
      }
      &--6 {
        background: #585858;
      }
      &--7 {
        background: #636363;
      }
      &--8 {
        background: #6E6E6E;
      }    
      &--9 {
        background: #848484;
      }
      &--10 {
        background: #A5A5A5;
      }
      &--11 {
        background: #A5A5A5;
      }
      &--12 {
        background: #B0B0B0;
      }
      &--13 {
        background:#D1D1D3;
      }
      &--14 {
        background: #DCDCDC;
      }
      &--15 {
        background: #E7E7E7;
      }
      &--16 {
        background: #FFFFFF;
      }       
    }
  
    &__logo {
      width: 100%;
      height: 160px;
      background: #fff;
      background-image: url('/assets/images/logo-picpary.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 250px;
    }
  
    &__info {
      height: 160px;
      background: #264653;
      text-align: center;
      color: #fff;
      display: flex;  
      align-items: center;
      justify-content: center;       
      position: relative;
    }
  
    &__data {
      font-family: 'Titillium Web', sans-serif;
    }
  
    &__hashtag {
      font-size: 32px;
      margin-bottom: 10px;
    }
  
    &__id {
      font-size: 20px;
    }
  
    &__qr--left, &__qr--right {
      position: absolute;
      top: 20px;
    }
  
    &__qr--left {
      left: 20px;
    }
  
    &__qr--right {
      right: 20px;
    }  
  }