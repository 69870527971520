.l-not-found-screen {
    background: #F6F8FA;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &__content {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__info {
        padding-left: 50px;
        max-width: 700px;
    }

    &__title {
        position: relative;
        margin-bottom: 40px;

        &:after {
            display: table;
            content: '';
            background: var(--main-color);
            width: 40px;
            height: 6px;
            position: absolute;
            left: 0;
            bottom: -22px;
        }
    }

    &__description {
        margin-bottom: 20px;
    }
}