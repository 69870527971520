.c-initial-screen {
    display: flex;

    &__figure {
        background: #fbbdc5;
        border-radius: 145px 45px 0 0;
        min-height: 510px;
        min-width: 500px;
        position: relative;

        img {
            bottom: -12px;
            position: absolute;
            left: -9px;            
        }
    }

    &__content {
        max-width: 500px;
        margin-left: 40px;
        color: #fff;
        font-size: 2.25rem;
        text-align: left;
    }

    &__title {
        font-size: 3rem;
        font-weight: 600;
        margin-bottom: 20px;
        display: block;
    }
}