.c-showroom {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    overflow: hidden;
    background: rgb(5, 68, 42);
    background: linear-gradient(52deg, rgba(5, 68, 42, 1) 0%, rgba(10, 135, 84, 1) 100%);
    padding: 160px 40px 60px 40px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &__fullscreen {
        height: 60px;
        width: 60px;
        background: #fff url('/assets/images/fullscreen.svg') center no-repeat;
        background-size: 45px;
        position: absolute;
        top: 140px;
        right: 40px;
        cursor: pointer;
        transition: background-size 0.25s;

        &:hover {
            background-size: 50px;
        }
    }
}