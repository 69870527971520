.c-challenge-timer {
  position: absolute;
  bottom: 40px;
  right: 40px;
  color: #fff;
  max-width: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__image {
    margin-bottom: 20px;
    width: max-content;
    height: 100px;
  }

  &__name {
    font-size: 1.875rem;
    font-weight: 600;
    line-height: 1.875rem;
  }

  &__hashtag {
    font-size: 1.875rem;
    font-weight: 600;
    color: #ACA5A1;
    margin-bottom: 5px;
  }

  &__countdown {
    font-size: 1.875rem;
    font-weight: 600;
    line-height: 2.25rem;
    width: 100%;
    background: #fff;
    color: #37474F;
  }
}