@import 'assets/scss/_mixins';

.c-header {
  font-family: 'Titillium Web', sans-serif;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include desktop {
    flex-direction: row;
    background: white;
    position: absolute;
    top: 0;
  }

  &__logo {
    height: 40px;
    background: white;
    padding: 10px 0;
    width: 100%;

    @include desktop {
      height: 60px;
      padding: 20px 40px;
      width: auto;
    }
  }

  &__info {
    font-size: 30px;
    color: white;
    font-weight: 300;
    margin-top: 16px;

    @include desktop {
      position: relative;
      flex: 1;
      font-size: 40px;
      font-weight: 400;
      color: #264653;
      text-align: right;
      padding-right: 40px;
      margin-top: 0;
    }
  }
}