.showroom-qr {
    svg {
        padding: 10px;
    }

    &__wrapper {
        position: absolute;
        bottom: 20px;
        right: 20px;
    }

    &__content {
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 10px;
    }

    &__confirmation-code {
        font-family: 'Titillium Web', sans-serif;
        font-size: 1.0rem;
        text-align: center;
        color: #fff;
        margin-bottom: 10px;

        &:before {
            content: '';
            display: inline-block;
            background: url('/assets/images/fingerprint.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 24px 24px;
            width: 24px;
            height: 24px;
            vertical-align: bottom;
            margin-right: 5px;
        }             
    }

    &__title {
        font-family: 'Titillium Web', sans-serif;
        font-size: 1.125rem;
        background: #444;
        text-align: center;
        color: #fff;
        padding: 10px;
        border-radius: 10px 10px 0 0;
    
        &:before {
            content: '';
            display: inline-block;
            background: url('/assets/images/whatsapp-icon.svg');
            background-repeat: no-repeat;
            background-position: center;
            width: 24px;
            height: 24px;
            vertical-align: sub;
            margin-right: 10px;
        }
    }
    
    &__code {
        font-family: 'Titillium Web', sans-serif;
        font-size: 1.25rem;
        background: #444;
        text-align: center;
        color: #fff;
        padding: 6px;
        border-radius: 0 0 10px 10px;

        &:before {
            content: '';
            display: inline-block;
            background: url('/assets/images/security-code.svg');
            background-repeat: no-repeat;
            background-position: center;
            width: 24px;
            height: 24px;
            vertical-align: sub;
            margin-right: 10px;
        }        
    }    
}