.c-video {
  box-sizing: border-box;
  background: #fff;
  box-shadow: 6px 6px 40px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  border-radius: 20px;
  position: relative;

  &__player {
    width: auto !important;
    height: 100% !important;

    video {
      width: auto !important;
      height: 100% !important;
    }
  }

  &__player {
    width: 100% !important;
    height: 100% !important;
  }

  &__info {
    position: relative;
    height: 140px;
    padding: 30px 60px 20px 60px;
    box-sizing: border-box;
  }

  &__avatar {
    width: 110px;
    border-radius: 80px;
    position: absolute;
    left: 0;
    right: 0;
    top: -85px;
    margin: auto;
  }

  &__user {
    font-size: 1.625rem;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__extra {
    font-size: 1.4375rem;
    font-weight: 300;
    color: var(--gray-color-40);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;    
  }
}