:global(html, body, #root) {
    overflow: auto !important;
    min-height: 100vh;
}

.l-album {
    background: rgb(243,91,107);
    background: linear-gradient(180deg, rgba(243,91,107,1) 0%, rgba(247,120,136,1) 100%);
    min-height: 100vh;
    text-align: center;

    section {
        position: relative;
    }

    &__date {
        background: #444444;
        color: #fff;
        font-weight: 600;
        font-size: 16px;
        display: inline-block;
        border-radius: 20px;
        padding: 3px 20px;
        margin-top: 10px;
    }

    &__container {
        padding: 20px;
    }

    &__photo,
    &__video {
        img {
            border-radius: 10px;
        }
    }

    &__video {
        position: relative;
        border-radius: 20px;
        
        &:after {
            content: '';
            position: absolute;
            background: url('/assets/images/play-circle.svg') center no-repeat;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 50%;
            height: 50%;
            background-size: 50%;         
            pointer-events: none;   
        }
    }

    &__loading {
        margin-top: 45px;
    }

    &__copyright {
        color: #fff;
        padding-bottom: 65px;
    }
}