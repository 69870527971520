.c-presentation-screen {
  height: 100vh;
  width: 100vw;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;  
  background-image: url('/assets/images/joe-ciciarelli-pYjRGc81avs-unsplash.jpg');

  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background: #000;
    opacity: 0.6;
    position: absolute;
    z-index: 9;
  }

  &__content {
    text-align: center;
    z-index: 99;
  }

  &__title {
    color: #fff;
    font-size: 3.75rem;
    font-weight: 600;
    line-height: 3.75rem;
  }  

  &__hashtag {
    color: var(--gray-color-10);
    font-size: 2.5rem;
    font-weight: 500;
  }

  &__logo {
    position: absolute;
    bottom: 40px;
    right: 40px;
    z-index: 99;
  }

  &__welcome {
    font-size: 2.125rem;
    font-weight: 300;
    color: #fff;
    max-width: 50vw;
  }

  &__countdown {
    margin-top: 90px;

    &-title {
      color: var(--gray-color-10);
      font-size: 40px;
      font-weight: 600;
    }

    &-elements {
      display: flex;
      justify-content: space-between;
    }

    &-element {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &-value {
        color: #fff;
        font-size: 4.375rem;
        font-weight: 600;
      }

      &-label {
        color: var(--gray-color-10);
        font-size: 1.875rem;
        font-weight: 300;
      }
    }
  }
}