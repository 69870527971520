.c-image {
  box-sizing: border-box;
  background: #fff;
  box-shadow: 6px 6px 40px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  
  &__info {
    position: relative;
    height: 140px;
    padding: 30px 60px 20px 60px;
    box-sizing: border-box;
  }

  &__avatar {
    width: 110px;
    border-radius: 80px;
    position: absolute;
    left: 0;
    right: 0;
    top: -85px;
    margin: auto;
  }

  &__user {
    font-size: 1.625rem;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &__extra {
    font-size: 1.4375rem;
    font-weight: 300;
    color: var(--gray-color-40);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    strong {
      font-weight: 600;
      //font-style: italic;
      color: var(--gray-color-20);
    }
  }
}