.f-authorization {
    background: var(--gray-light);
    width: 100vw;
    height: 100vh;  
    max-width: 100%;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &__block {
        &-logo {
            margin-bottom: 40px;
        }

        &-actions {
            color: var(--gray-color-20);
        }
    }

    &__loader {
        position: absolute;
        bottom: 0;
        width: 100vw;
    }
}