.l-challenge {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    padding: 140px 40px 40px 40px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100vw;
        height: 100vh;
        background: #000;
        opacity: 0.6;
    }

    &__fullscreen {
        height: 60px;
        width: 60px;
        background: #fff url('/assets/images/fullscreen.svg') center no-repeat;
        background-size: 45px;
        position: absolute;
        top: 140px;
        right: 40px;
        cursor: pointer;
        transition: background-size 0.25s;

        &:hover {
            background-size: 50px;
        }
    }
}