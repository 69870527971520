@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');

:root {
  --main-color: #FA6161;
  --gray-color: #222D32;
  --gray-color-80: #364146;
  --gray-color-60: #4A555A;
  --gray-color-40: #5E696E;
  --gray-color-20: #727D82;
  --gray-color-10: #AEB4B8;
  --gray-light: #F6F8FA;
  --blue-color: #31A5B4;
  --error-color: #d32f2f;
}

html, body, #root {
  font-family: 'Titillium Web', 'Helvetica', sans-serif;
  height: 100vh;
  margin: 0;
  margin: 0; 
  padding: 0; 
  overflow: hidden;
}

input {
  font-family: 'Titillium Web', 'Helvetica', sans-serif;
}

a {
  color: var(--main-color);

  &:hover {
    opacity: 0.8;
  }
}